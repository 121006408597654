<template>
  <div>
    <KTCard ref="preview" v-bind:title="title">
      <!--页面标题-->
      <template v-slot:title>
        <h3 class="card-title">
          <b-icon icon="blockquote-right" class="mx-3"></b-icon>
          提现管理
        </h3>
      </template>

      <!--操作按钮组-->
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <!--按钮组-->
          <b-button variant="success" @click="audit()" class="mx-2">
            <b-icon icon="pencil"></b-icon>
            提现审核
          </b-button>

          <!--按钮组-->
          <b-button variant="warning" @click="dispatch()" class="mx-2">
            <i class="flaticon2-paper-plane mr-1"></i>
            提交代付
          </b-button>

          <b-dropdown variant="primary" right text="导入导出" class="m-2">
            <b-dropdown-item-button class="rounded" @click="exportWithdraw">
              <i class="flaticon-download-1 mr-2"></i>
              导出提现记录
            </b-dropdown-item-button>
          </b-dropdown>
          <!--按钮组-->
        </div>
      </template>

      <!--数据表格-->
      <template v-slot:body>
        <div style="min-height: 70vh">
          <!--表格-->
          <v-data-table show-select fixed-header dense height="70vh" v-model="table.selected" :headers="table.headers"
            :items="table.desserts" :single-select="table.singleSelect" :footer-props="table.footer"
            :server-items-length="table.itemsCount" :options.sync="table.pagination" :no-data-text="table.noDataText"
            :loading="table.loading" :loading-text="table.loadingText" item-key="id">
            <template v-slot:item.type="{ item }">
              {{
                  filter.typeOptions.find(
                    (typeItem) => typeItem.value == item.type
                  ).text
              }}
            </template>

            <template v-slot:item.merchantId="{ item }">
              <div>{{ item.merchantName }} [{{ item.merchantId }}]</div>
            </template>

            <template v-slot:item.channelId="{ item }">
              <div>{{ item.channelName }} [{{ item.channelId }}]</div>
            </template>

            <template v-slot:item.status="{ item }">
              {{
                  filter.statusOptions.find(
                    (statusItem) => statusItem.value == item.status
                  ).text
              }}
            </template>

            <template v-slot:item.notifyStatus="{ item }">
              <span v-if="item.notifyStatus == 0"> 待回调 </span>
              <span v-else-if="item.notifyStatus == 1"> 已回调 </span>
              <span v-else-if="item.notifyStatus == 2"> 无需回调 </span>
            </template>

            <template v-slot:item.amount="{ item }">
              {{ item.amount / 100.0 }}
            </template>

            <template v-slot:item.tradeFee="{ item }">
              {{ item.tradeFee / 100.0 }}
            </template>

            <template v-slot:item.createTime="{ item }">
              {{ item.createTime | formatDate }}
            </template>

            <!--状态字段   格式处理-->
            <template v-slot:item.action="{ item }">
              <v-btn small text icon fab v-b-tooltip.hover.lefttop title="手动回调" v-on:click="manualNotify(item.orderId)">
                <v-icon>la la-reply</v-icon>
              </v-btn>

              <v-btn small text icon fab v-b-tooltip.hover.lefttop title="审核"
                v-if="item.status == 1 || item.status == 2" v-on:click="audit(item)">
                <v-icon>la la-check-circle-o</v-icon>
              </v-btn>

              <v-btn small text icon fab v-b-tooltip.hover.lefttop title="人工打款"
                v-if="item.status == 2 || item.status == 6 || item.status == 7" v-on:click="transfer(item)">
                <v-icon>la la-money-check</v-icon>
              </v-btn>

              <v-btn small text icon fab v-b-tooltip.hover.lefttop title="同步状态"
                v-if="item.status == 3 || item.status == 7" v-on:click="syncStatus(item.orderId)">
                <v-icon>la-refresh</v-icon>
              </v-btn>

              <v-btn small text icon fab v-b-tooltip.hover.lefttop title="变更状态"
                v-if="item.status == 3 || item.status == 5 || item.status == 7" v-on:click="changeStatus(item)">
                <v-icon>la-gear</v-icon>
              </v-btn>

              <v-btn small text icon fab v-b-tooltip.hover.lefttop title="提现详情" v-on:click="showDetail(item.id)">
                <v-icon>la-poll-h</v-icon>
              </v-btn>
            </template>

            <!--表格筛选-->
            <template v-slot:top>
              <b-form inline>
                <b-form-group>
                  <b-form-select :options="filter.conditions" v-model="filter.field"></b-form-select>
                </b-form-group>
                <label class="after-label ml-2 pb-md-2 pb-lg-2 pb-xl-2 pb-sm-3 pb-3">:</label>
                <b-form-group class="mx-2">
                  <b-form-input id="input-2" v-model="filter.keyword" placeholder="检索关键词"></b-form-input>
                </b-form-group>

                <b-form-group class="mx-2 ml-md-2 ml-lg-2 ml-xl-2 ml-sm-0 ml-0">
                  <b-form-select :options="filter.typeOptions" v-model="filter.type"></b-form-select>
                </b-form-group>

                <b-form-group class="mx-2">
                  <date-picker range v-model="filter.dateRange" type="datetime" format="yyyy-MM-dd HH:mm:ss"
                    popper-class="datetime-popper" value-class="datetime-picker-input">
                  </date-picker>
                </b-form-group>
                <!-- <b-form-datepicker locale="zh" v-model="filter.startTime" placeholder="起始日期" :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"></b-form-datepicker> -->
                <!-- <b-form-group class="mx-2">
                  <date-picker
                    v-model="filter.endTime"
                    type="datetime"
                    format="yyyy-MM-dd HH:mm:ss"
                    popper-class="datetime-popper"
                    value-class="datetime-picker-input"
                    placeholder="结束日期"
                  >
                  </date-picker>
                </b-form-group> -->
                <!-- <b-form-datepicker class="mx-2 ml-md-2 ml-lg-2 ml-xl-2 ml-sm-0 ml-0" locale="zh"
                  v-model="filter.endTime" placeholder="结束日期" :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"></b-form-datepicker> -->

                <b-form-group class="mx-2 ml-md-2 ml-lg-2 ml-xl-2 ml-sm-0 ml-0">
                  <b-form-select :options="filter.statusOptions" v-model="filter.status"></b-form-select>
                </b-form-group>

                <b-form-group class="mx-2">
                  <b-button variant="success" v-on:click="getData()">
                    <v-icon small> flaticon-search</v-icon>
                    查询
                  </b-button>
                  <v-btn v-if="filter.tooltips_visible" color="success" v-on:click="filter.tooltips_visible = false" fab
                    small class="ml-2">
                    <v-icon>fas fa-chevron-up</v-icon>
                  </v-btn>

                  <v-btn v-else color="success" v-on:click="filter.tooltips_visible = true" fab small class="ml-2">
                    <v-icon>fas fa-chevron-down</v-icon>
                  </v-btn>
                </b-form-group>
              </b-form>

              <b-row class="ml-2 mt-1 mb-0">
                <b-col cols="12" class="m-0 p-0">
                  <b-collapse v-model="filter.tooltips_visible">
                    <b-row class="m-0 p-0">
                      <b-col cols="12" class="m-0 mt-1 mb-2 p-0">
                        <div class="separator separator-dashed"></div>
                      </b-col>

                      <b-col cols="12" class="m-0 p-0">
                        <div class="d-flex align-items-center flex-wrap mt-0">
                          <div class="d-flex align-items-center flex-lg-fill mr-5">
                            <span class="mr-4"> 订单总量 </span>
                            <div class="d-flex flex-column text-dark-75">
                              <span class="font-weight-bolder font-size-h5">
                                {{ table.statistic.totalCount || 0 }}
                              </span>
                            </div>
                          </div>

                          <div class="d-flex align-items-center flex-lg-fill mr-5">
                            <span class="mr-4"> 订单总金额 </span>
                            <div class="d-flex flex-column text-dark-75">
                              <span class="font-weight-bolder font-size-h5">
                                <span class="text-dark-50 font-weight-bold">$</span>
                                {{ table.statistic.totalAmount / 100.0 || 0.0 }}
                              </span>
                            </div>
                          </div>

                          <div class="d-flex align-items-center flex-lg-fill mr-5">
                            <span class="mr-4"> 成功数量 </span>
                            <div class="d-flex flex-column text-dark-75">
                              <span class="font-weight-bolder font-size-h5">
                                {{ table.statistic.successCount || 0 }}
                              </span>
                            </div>
                          </div>

                          <div class="d-flex align-items-center flex-lg-fill mr-5">
                            <span class="mr-4"> 成功金额 </span>
                            <div class="d-flex flex-column text-dark-75">
                              <span class="font-weight-bolder font-size-h5">
                                <span class="text-dark-50 font-weight-bold">$</span>
                                {{
                                    table.statistic.successAmount / 100.0 || 0.0
                                }}
                              </span>
                            </div>
                          </div>

                          <div class="d-flex align-items-center flex-lg-fill mr-5">
                            <span class="mr-4"> 失败数量 </span>
                            <div class="d-flex flex-column text-dark-75">
                              <span class="font-weight-bolder font-size-h5">
                                {{ table.statistic.failCount }}
                              </span>
                            </div>
                          </div>

                          <div class="d-flex align-items-center flex-lg-fill mr-5">
                            <span class="mr-4"> 失败金额 </span>
                            <div class="d-flex flex-column text-dark-75">
                              <span class="font-weight-bolder font-size-h5">
                                <span class="text-dark-50 font-weight-bold">$</span>
                                {{ table.statistic.failAmount / 100.0 || 0.0 }}
                              </span>
                            </div>
                          </div>

                          <div class="d-flex align-items-center flex-lg-fill mr-5">
                            <span class="mr-4"> 成功率 </span>
                            <div class="d-flex flex-column text-dark-75">
                              <span class="font-weight-bolder font-size-h5">
                                <span class="text-dark-50 font-weight-bold"></span>
                                {{
                                    table.statistic.successRate
                                      ? table.statistic.successRate.toFixed(2)
                                      : 0.0
                                }}
                                %
                              </span>
                            </div>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </b-collapse>
                </b-col>
              </b-row>

              <!--分隔线-->
              <v-divider></v-divider>
            </template>
          </v-data-table>
        </div>
      </template>
    </KTCard>

    <!--模态框-审核-->
    <b-modal ref="audit-modal" size="lg" centered title="商户提现审核" static="true">
      <b-container fluid>
        <b-form id="auditForm" method="POST" class="form" novalidate="novalidate">
          <b-row class="mb-0 pb-0">
            <b-col class="mb-0" cols="6">
              <b-form-group label="商户名称:">
                <b-form-input type="text" disabled readonly name="merchantName" v-model="currentAccount.merchantName">
                </b-form-input>
              </b-form-group>
              <b-form-group v-if="current.type != 3" label="银行编码:">
                <b-form-input type="text" disabled readonly name="merchantName" v-model="current.bankCode">
                </b-form-input>
              </b-form-group>
              <b-form-group v-if="current.type != 3" label="持卡人姓名:">
                <b-form-input type="text" disabled readonly name="merchantName" v-model="current.accountName">
                </b-form-input>
              </b-form-group>
              <b-form-group v-if="current.type == 3" label="代币类型:">
                <b-form-input type="text" disabled readonly name="amount" v-model="current.walletTypeName">
                </b-form-input>
              </b-form-group>
              <b-form-group label="账户余额:">
                <b-form-input type="text" disabled readonly name="amount" v-model="currentAccount.balance">
                </b-form-input>
              </b-form-group>
            </b-col>

            <b-col class="mb-0" cols="6">
              <b-form-group label="提现订单号:">
                <b-form-input type="text" disabled readonly name="amount" v-model="current.orderId">
                </b-form-input>
              </b-form-group>
              <b-form-group v-if="current.type == 3" label="钱包地址:">
                <b-form-input type="text" disabled readonly name="walletAddress" v-model="current.walletAddress">
                </b-form-input>
              </b-form-group>
              <b-form-group v-if="current.type != 3" label="银行名称:">
                <b-form-input type="text" disabled readonly name="merchantName" v-model="current.bankName">
                </b-form-input>
              </b-form-group>
              <b-form-group v-if="current.type != 3" label="银行卡账号:">
                <b-form-input type="text" disabled readonly name="merchantName" v-model="current.cardNumber">
                </b-form-input>
              </b-form-group>
              <b-form-group label="提现金额:">
                <b-form-input type="text" name="amount" v-model="current.amount">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-0 pt-0">
            <b-col class="mt-0 pt-0" cols="12">
              <b-form-group label="备注:">
                <b-form-input type="text" name="remark" v-model="current.remark" placeholder="请输入备注...">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-container>
      <template v-slot:modal-footer>
        <b-button v-on:click="submitAudit(2)" variant="primary">通过审核
        </b-button>
        <b-button v-on:click="submitAudit(4)" variant="danger">拒绝申请
        </b-button>
        <b-button v-on:click="$refs['audit-modal'].hide()">取消</b-button>
      </template>
    </b-modal>
    <!--模态框-审核-->

    <!--模态框-人工打款-->
    <b-modal ref="transfer-modal" size="lg" static="true" centered title="人工打款">
      <b-container fluid>
        <b-form id="transferForm" method="POST" class="form" novalidate="novalidate">
          <b-row class="mb-0 pb-0">
            <b-col class="mb-0" cols="6">
              <b-form-group label="商户名称:">
                <b-form-input type="text" disabled readonly name="merchantName" v-model="currentAccount.merchantName">
                </b-form-input>
              </b-form-group>
              <b-form-group v-if="current.type != 3" label="银行编码:">
                <b-form-input type="text" disabled readonly name="merchantName" v-model="current.bankCode">
                </b-form-input>
              </b-form-group>
              <b-form-group v-if="current.type != 3" label="持卡人姓名:">
                <b-form-input type="text" disabled readonly name="merchantName" v-model="current.accountName">
                </b-form-input>
              </b-form-group>
              <b-form-group label="账户余额:">
                <b-form-input type="text" disabled readonly name="amount" v-model="currentAccount.balance">
                </b-form-input>
              </b-form-group>
              <b-form-group label="转账时间:">
                <b-row class="m-0 p-0">
                  <b-col cols="6" class="m-0 p-0">
                    <b-form-datepicker locale="zh" name="transferDate" v-model="current.transferDate" placeholder="起始日期"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"></b-form-datepicker>
                  </b-col>
                  <b-col cols="6" class="m-0 p-0">
                    <b-form-timepicker locale="zh" name="transferTime" v-model="current.transferTime" :hour12="false"
                      :show-seconds="true">
                    </b-form-timepicker>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>

            <b-col class="mb-0" cols="6">
              <b-form-group label="提现订单号:">
                <b-form-input type="text" disabled readonly name="amount" v-model="current.orderId">
                </b-form-input>
              </b-form-group>
              <b-form-group v-if="current.type != 3" label="银行名称:">
                <b-form-input type="text" disabled readonly name="merchantName" v-model="current.bankName">
                </b-form-input>
              </b-form-group>
              <b-form-group v-if="current.type != 3" label="银行卡账号:">
                <b-form-input type="text" disabled readonly name="merchantName" v-model="current.cardNumber">
                </b-form-input>
              </b-form-group>
              <b-form-group label="提现金额:">
                <b-form-input type="text" name="amount" v-model="current.amount">
                </b-form-input>
              </b-form-group>
              <b-form-group label="回执编号:">
                <b-form-input type="text" name="receipt" v-model="current.receipt">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-0">
            <b-col class="mt-0" cols="12">
              <b-form-group label="备注:">
                <b-form-input type="text" name="remark" v-model="current.remark" placeholder="请输入备注...">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-container>

      <!---->
      <template v-slot:modal-footer>
        <b-button v-on:click="submitTransfer()" variant="primary">提交
        </b-button>

        <b-button v-on:click="$refs['transfer-modal'].hide()">取消</b-button>
      </template>
    </b-modal>
    <!--模态框-人工打款-->

    <!--模态框-更改订单状态-->
    <b-modal ref="status-modal" static="true" centered title="更改订单状态">
      <b-container fluid>
        <b-form id="statusForm" method="POST" class="form" novalidate="novalidate">
          <b-row>
            <b-col cols="3" class="text-right">
              <label class="form-group-label">订单号 :</label>
            </b-col>
            <b-col cols="8">
              <b-form-group>
                <b-form-input type="text" name="specs" readonly v-model="statusForm.orderId"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="3" class="text-right">
              <label class="form-group-label">状态 :</label>
            </b-col>
            <b-col cols="8">
              <b-form-group>
                <v-radio-group class="mt-0" row name="status" v-model="statusForm.status" :mandatory="false">
                  <v-radio label="提现成功" :value="5"></v-radio>
                  <v-radio label="提现失败" :value="6"></v-radio>
                </v-radio-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-container>

      <template v-slot:modal-footer>
        <b-button v-on:click="$refs['status-modal'].hide()">取消</b-button>
        <b-button v-on:click="submitChnageStatus()" variant="primary">提交</b-button>
      </template>
    </b-modal>
    <!--模态框-更改订单状态-->

    <!--模态框-订单详情-->
    <b-modal ref="detail-modal" size="lg" static="true" centered title="订单详情">
      <b-container fluid>
        <b-row>
          <b-col class="p-0 m-0 mr-1">
            <b-list-group>
              <b-list-group-item class="d-flex justify-content-between align-items-center">
                <b-badge class="mr-1" variant="light">平台订单号</b-badge>
                {{ current.orderId }}
              </b-list-group-item>

              <b-list-group-item class="d-flex justify-content-between align-items-center">
                <b-badge class="mr-1" variant="light">商户名称</b-badge>
                {{ current.merchantName }}
              </b-list-group-item>

              <b-list-group-item class="d-flex justify-content-between align-items-center">
                <b-badge class="mr-1" variant="light">提现方式</b-badge>
                {{
                    filter.typeOptions.find(
                      (typeItem) => typeItem.value == current.type
                    ).text
                }}
              </b-list-group-item>

              <b-list-group-item v-if="current.type == 3" class="d-flex justify-content-between align-items-center">
                <b-badge class="mr-1" variant="light">代币类型</b-badge>

                <span v-if="current.walletType == 1"> TRC20</span>
                <span v-else-if="current.walletType == 2"> ERC20</span>
              </b-list-group-item>

              <b-list-group-item class="d-flex justify-content-between align-items-center">
                <b-badge class="mr-1" variant="light">钱包地址</b-badge>
                {{ current.walletAddress }}
              </b-list-group-item>

              <b-list-group-item v-if="current.type != 3" class="d-flex justify-content-between align-items-center">
                <b-badge class="mr-1" variant="light">持卡人姓名</b-badge>
                {{ current.accountName }}
              </b-list-group-item>

              <b-list-group-item v-if="current.type != 3" class="d-flex justify-content-between align-items-center">
                <b-badge class="mr-1" variant="light">银行卡账号</b-badge>
                {{ current.cardNumber }}
              </b-list-group-item>
              <b-list-group-item v-if="current.type != 3" class="d-flex justify-content-between align-items-center">
                <b-badge class="mr-1" variant="light">银行名称</b-badge>
                {{ current.bankName }}
              </b-list-group-item>
              <b-list-group-item v-if="current.type != 3" class="d-flex justify-content-between align-items-center">
                <b-badge class="mr-1" variant="light">银行编码</b-badge>
                {{ current.bankCode }}
              </b-list-group-item>

              <b-list-group-item class="d-flex justify-content-between align-items-center">
                <b-badge class="mr-1" variant="light">申请时间</b-badge>
                {{ current.createTime | formatDate }}
              </b-list-group-item>
              <b-list-group-item v-if="current.type != 3" class="d-flex justify-content-between align-items-center">
                <b-badge class="mr-1" variant="light">通道订单号</b-badge>
                {{ current.channelOrderId }}
              </b-list-group-item>
              <b-list-group-item v-if="current.type != 3" class="d-flex justify-content-between align-items-center">
                <b-badge class="mr-1" variant="light">通道状态码</b-badge>
                {{ current.channelResultCode }}
              </b-list-group-item>
              <b-list-group-item v-if="current.type != 3" class="d-flex justify-content-between align-items-center">
                <b-badge class="mr-1" variant="light">回调次数</b-badge>
                {{ current.notifyTimes }}
              </b-list-group-item>
              <b-list-group-item
                class="d-flex justify-content-between align-items-center"
              >
                <b-badge class="mr-1" variant="light">通道响应耗时</b-badge>
                {{ current.delayTime  }} 毫秒
              </b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col class="p-0 m-0 ml-1">
            <b-list-group>
              <b-list-group-item class="d-flex justify-content-between align-items-center">
                <b-badge class="mr-1" variant="light">商户订单号</b-badge>
                {{ current.outOrderId }}
              </b-list-group-item>

              <b-list-group-item v-if="current.type != 3" class="d-flex justify-content-between align-items-center">
                <b-badge class="mr-1" variant="light">通道名称</b-badge>
                {{ current.channelName }}
              </b-list-group-item>

              <b-list-group-item class="d-flex justify-content-between align-items-center">
                <b-badge class="mr-1" variant="light">提现金额</b-badge>
                {{ current.amount / 100.0 }}
              </b-list-group-item>

              <b-list-group-item class="d-flex justify-content-between align-items-center">
                <b-badge class="mr-1" variant="light">手续费</b-badge>
                {{ current.tradeFee / 100.0 }}
              </b-list-group-item>

              <b-list-group-item v-if="current.type != 3" class="d-flex justify-content-between align-items-center">
                <b-badge class="mr-1" variant="light">收款人电话</b-badge>
                {{ current.payeePhone }}
              </b-list-group-item>

              <b-list-group-item v-if="current.type != 3" class="d-flex justify-content-between align-items-center">
                <b-badge class="mr-1" variant="light">收款人邮箱</b-badge>
                {{ current.payeeEmail }}
              </b-list-group-item>

              <b-list-group-item class="d-flex justify-content-between align-items-center">
                <b-badge class="mr-1" variant="light">转账流水号</b-badge>
                {{ current.resn }}
              </b-list-group-item>
              <b-list-group-item class="d-flex justify-content-between align-items-center">
                <b-badge class="mr-1" variant="light">状态</b-badge>
                {{
                    filter.statusOptions.find(
                      (statusItem) => statusItem.value == current.status
                    ).text
                }}
              </b-list-group-item>
              <b-list-group-item class="d-flex justify-content-between align-items-center">
                <b-badge class="mr-1" variant="light">转账时间</b-badge>
                {{ current.transferTime | formatDate }}
              </b-list-group-item>
              <b-list-group-item v-if="current.type != 3" class="d-flex justify-content-between align-items-center">
                <b-badge class="mr-1" variant="light">通道响应结果</b-badge>
                {{ current.channelResultMsg }}
              </b-list-group-item>
              <b-list-group-item v-if="current.type != 3" class="d-flex justify-content-between align-items-center">
                <b-badge class="mr-1" variant="light">回调地址</b-badge>
                {{ current.notifyUrl }}
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
      </b-container>
      <template v-slot:modal-footer>
        <b-button v-on:click="$refs['detail-modal'].hide()">关闭</b-button>
      </template>
    </b-modal>
    <!--模态框-订单详情-->
  </div>
</template>

<script>
import Vue from "vue";

import KTCard from "@/view/content/Card.vue";
import KTUtil from "@/assets/js/components/util";

import ApiService from "@/core/services/api.service";

// FormValidation plugins
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import Swal from "sweetalert2";
import Moment from "moment";
import DatePicker from "@wangankeji/vue-datepicker";
import "@wangankeji/vue-datepicker/dist/datepicker.css";

export default {
  name: "List",
  components: {
    KTCard,
    DatePicker,
  },
  props: {
    title: String,
  },
  data() {
    return {
      current: {},
      currentAccount: {},
      filter: {
        tooltips_visible: false,
        conditions: [
          {
            value: null,
            text: "检索条件",
          },
          {
            value: "orderId",
            text: "平台订单号",
          },
          {
            value: "outOrderId",
            text: "商户订单号",
          },
          {
            value: "merchantId",
            text: "商户ID",
          },
          {
            value: "channelId",
            text: "通道ID",
          },
        ],
        typeOptions: [
          {
            value: null,
            text: "提现方式",
          },
          {
            value: 1,
            text: "API申请",
          },
          {
            value: 2,
            text: "页面申请(银行卡)",
          },
          {
            value: 3,
            text: "页面申请(USDT)",
          },
        ],
        statusOptions: [
          {
            value: null,
            text: "提现状态",
          },
          {
            value: 1,
            text: "待审核",
          },
          {
            value: 2,
            text: "审核通过,待打款",
          },
          {
            value: 3,
            text: "渠道打款中",
          },
          {
            value: 4,
            text: "审核不通过",
          },
          {
            value: 5,
            text: "提现到账",
          },
          {
            value: 6,
            text: "提现失败",
          },
          {
            value: 7,
            text: "提现异常",
          },
        ],
        field: null,
        keyword: "",
        dateRange: [Moment(new Date(Date.now() - (7 * 24 * 60 * 60 * 1000))).format("YYYY-MM-DD") + " 00:00:00", Moment(new Date()).format("YYYY-MM-DD") + " 23:59:59"],
        type: null,
        status: null,
      },
      formModel: {
        id: "",
      },
      statusForm: {},

      table: {
        loadingText: "正在加载...",
        noDataText: "暂无数据...",
        loading: false,
        singleSelect: false,
        selected: [],
        itemsCount: 50,
        headers: [
          {
            text: "平台订单号",
            value: "orderId",
          },
          {
            text: "商户订单号",
            value: "outOrderId",
          },
          {
            text: "通道订单号",
            value: "channelOrderId",
          },
          {
            text: "商户",
            value: "merchantId",
          },
          {
            text: "提现方式",
            value: "type",
          },
          {
            text: "提现金额",
            value: "amount",
          },
          {
            text: "手续费",
            value: "tradeFee",
          },
          {
            text: "通道",
            value: "channelId",
          },
          {
            text: "备注",
            value: "remark",
          },

          {
            text: "提现状态",
            value: "status",
          },
          {
            text: "通知状态",
            value: "notifyStatus",
          },
          {
            text: "申请时间",
            value: "createTime",
          },

          {
            text: "操作",
            value: "action",
          },
        ],
        footer: {
          itemsPerPageOptions: [5, 10, 20, 50, 100],
          itemsPerPageText: "分页数量",
          showCurrentPage: true,
        },
        pagination: {
          itemsPerPage: 20,
        },
        desserts: [],
        statistic: {},
      },
    };
  },
  mounted() {
    // Create a FormValidation instance
    let transferForm = KTUtil.getById("transferForm");
    this.fv = formValidation(transferForm, {
      fields: {
        receipt: {
          validators: {
            notEmpty: {
              message: "转账回执不能为空!",
            },
          },
        },
        type: {
          validators: {
            notEmpty: {
              message: "提现方式: 1、API提交; 2、页面提交不能为空!",
            },
          },
        },
        orderId: {
          validators: {
            notEmpty: {
              message: "提现单号不能为空!",
            },
          },
        },
        amount: {
          validators: {
            notEmpty: {
              message: "提现金额:单位/分不能为空!",
            },
          },
        },
        transferDate: {
          validators: {
            notEmpty: {
              message: "请选择转账日期!",
            },
          },
        },
        transferTime: {
          validators: {
            notEmpty: {
              message: "请选择转账时间!",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        bootstrap: new Bootstrap(),
      },
    });

    let statusForm = KTUtil.getById("statusForm");
    this.fv1 = formValidation(statusForm, {
      fields: {
        status: {
          validators: {
            notEmpty: {
              message: "请选择变更状态!",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        bootstrap: new Bootstrap(),
      },
    });
  },
  created() { },

  methods: {
    getData() {
      // let startTime = this.filter.startTime,
      //   endTime = this.filter.endTime;
      // if (startTime) {
      //   startTime = startTime + " 00:00:00";
      // }
      // if (endTime) {
      //   endTime = endTime + " 23:59:59";
      // }

      let params = {
        pageNum: this.table.pagination.page,
        pageSize: this.table.pagination.itemsPerPage,
        startTime: this.filter.dateRange[0],
        endTime: this.filter.dateRange[1],
        type: this.filter.type,
        status: this.filter.status,
      };
      params["" + this.filter.field + ""] = this.filter.keyword;

      ApiService.query("/api/merchant/withdraw/query_list", {
        params: params,
      }).then(({ data }) => {
        this.table.desserts = data.result.data;
        this.table.itemsCount = data.result.total;
        this.table.statistic = data.result.statistic;
      });
    },

    getAccount(merchantId) {
      this.currentAccount = {};
      let that = this;
      ApiService.query("/api/merchant/account/query_by_id", {
        params: {
          merchantId: merchantId,
        },
      }).then(({ data }) => {
        that.currentAccount = data.result;
        that.currentAccount.balance = that.currentAccount.balance / 100.0;
      });
    },
    getSelectedItems() {
      let items = [];
      if (this.table.selected != null && this.table.selected != undefined) {
        this.table.selected.forEach(function (item) {
          items.push(item);
        });
      }
      return items;
    },

    audit(item = undefined) {
      item = item == undefined ? this.getSelectedItems() : [item];
      if (item.length != 1) {
        Swal.fire({
          title: "",
          text: "请选择要审核的数据行,一次仅且只能选择一条!",
          icon: "warning",
          confirmButtonClass: "btn btn-success",
          heightAuto: false,
        });
        return;
      }
      this.current = JSON.parse(JSON.stringify(item[0]));
      if (this.current.status == 1 || this.current.status == 2) {
        let amount = this.current.amount;
        this.current.amount = amount / 100.0;
        this.current.walletTypeName = this.current.walletType == 1 ? "TRC20" : "ERC20";
        this.getAccount(this.current.merchantId);
        this.$refs["audit-modal"].show();
      } else {
        Swal.fire({
          title: "",
          text: "非待审核提现订单，不可再次审核!",
          icon: "warning",
          confirmButtonClass: "btn btn-success",
          heightAuto: false,
        });
        return;
      }

    },

    submitAudit(status) {
      let that = this;
      ApiService.post("/api/merchant/withdraw/manage/audit", {
        id: that.current.id,
        remark: that.current.remark,
        status: status,
      }).then(({ data }) => {
        if (data.success) {
          Swal.fire({
            title: "",
            text: "提交成功！",
            icon: "success",
            confirmButtonClass: "btn btn-success",
            heightAuto: false,
            timer: 1500,
          });
          that.getData();
          that.$refs["audit-modal"].hide();
        } else {
          Swal.fire({
            title: "提交失败！",
            text: data.message,
            icon: "warning",
            confirmButtonClass: "btn btn-success",
            heightAuto: false,
            timer: 3000,
          });
        }
      });
    },

    dispatch(item = undefined) {
      item = item == undefined ? this.getSelectedItems() : [item];
      if (item.length < 1) {
        Swal.fire({
          title: "",
          text: "请选择要提交至通道的的代付订单",
          icon: "warning",
          confirmButtonClass: "btn btn-success",
          heightAuto: false,
        });
        return;
      }

      let ids = [];
      console.info(item);
      for (let i = 0; i < item.length; i++) {
        ids.push(item[i].id);
      }
      console.info(ids);

      let that = this;
      Swal.fire({
        title: "确定提交订单至代付通道?",
        icon: "warning",
        buttons: true,
        showConfirmButton: true,
        confirmButtonText: "确定",
        showCancelButton: true,
        cancelButtonText: "取消",
        dangerMode: true,
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          ApiService.post("/api/merchant/withdraw/manage/dispatch", ids).then(
            ({ data }) => {
              if (data.success) {
                Swal.fire({
                  title: "",
                  text: "提交成功",
                  icon: "success",
                  confirmButtonClass: "btn btn-success",
                  heightAuto: false,
                  timer: 1500,
                });
                that.getData();
              } else {
                Swal.fire({
                  title: "提交失败！",
                  text: data.message,
                  icon: "warning",
                  confirmButtonClass: "btn btn-success",
                  heightAuto: false,
                  timer: 3000,
                });
              }
            }
          );
        }
      });
    },

    syncStatus(orderId) {
      let that = this;
      ApiService.post("/api/merchant/withdraw/manage/sync_status", [
        orderId,
      ]).then(({ data }) => {
        if (data.success) {
          Swal.fire({
            title: "",
            text: "操作成功！",
            icon: "success",
            confirmButtonClass: "btn btn-success",
            heightAuto: false,
            timer: 1500,
          });
          that.getData();
          that.$refs["audit-modal"].hide();
        } else {
          Swal.fire({
            title: "操作失败！",
            text: data.message,
            icon: "warning",
            confirmButtonClass: "btn btn-success",
            heightAuto: false,
            timer: 3000,
          });
        }
      });
    },

    changeStatus(item) {
      this.statusForm.id = item.id;
      // this.statusForm.orderId = item.orderId;
      this.$set(this.statusForm, "orderId", item.orderId);
      this.$refs["status-modal"].show();
    },

    submitChnageStatus() {
      let that = this;

      this.fv1.validate().then(function (status) {
        console.info(status);

        if (status === "Valid") {
          ApiService.post(
            "/api/merchant/withdraw/manage/change_status",
            that.statusForm
          ).then(({ data }) => {
            if (data.success) {
              Swal.fire({
                title: "",
                text: "提交成功！",
                icon: "success",
                confirmButtonClass: "btn btn-success",
                heightAuto: false,
                timer: 1500,
              });
              that.getData();
              that.$refs["status-modal"].hide();
            } else {
              Swal.fire({
                title: "提交失败！",
                text: data.message,
                icon: "warning",
                confirmButtonClass: "btn btn-success",
                heightAuto: false,
                timer: 3000,
              });
            }
          });
        }
      });
    },

    showDetail(id) {
      let that = this;
      ApiService.query("/api/merchant/withdraw/query_by_id", {
        params: {
          id: id,
        },
      }).then(({ data }) => {
        that.current = data.result;
        this.$refs["detail-modal"].show();
      });
    },
    transfer(item = undefined) {
      item = item == undefined ? this.getSelectedItems() : [item];
      if (item.length != 1) {
        Swal.fire({
          title: "",
          text: "请选择要审核的数据行,一次仅且只能选择一条!",
          icon: "warning",
          confirmButtonClass: "btn btn-success",
          heightAuto: false,
        });
        return;
      }
      this.current = JSON.parse(JSON.stringify(item[0]));
      if (
        this.current.status == 4 ||
        this.current.status == 5 ||
        this.current.status == 6
      ) {
        Swal.fire({
          title: "",
          text: "已处理订单，不可再次转账!",
          icon: "warning",
          confirmButtonClass: "btn btn-success",
          heightAuto: false,
        });
        return;
      }
      let amount = this.current.amount;
      this.current.amount = amount / 100.0;
      this.getAccount(this.current.merchantId);
      this.$refs["transfer-modal"].show();
    },
    submitTransfer() {
      let that = this;
      this.fv.validate().then(function (status) {
        if (status === "Valid") {
          let transferTime =
            that.current.transferDate + " " + that.current.transferTime;

          ApiService.post("/api/merchant/withdraw/manage/transfer", {
            id: that.current.id,
            receipt: that.current.receipt,
            transferTime: transferTime,
            remark: that.current.remark,
          }).then(({ data }) => {
            if (data.success) {
              Swal.fire({
                title: "",
                text: "提交成功！",
                icon: "success",
                confirmButtonClass: "btn btn-success",
                heightAuto: false,
                timer: 1500,
              });
              that.getData();
              that.$refs["transfer-modal"].hide();
            } else {
              Swal.fire({
                title: "提交失败！",
                text: data.message,
                icon: "warning",
                confirmButtonClass: "btn btn-success",
                heightAuto: false,
                timer: 3000,
              });
            }
          });
        }
      });
    },

    manualNotify(orderId = undefined) {
      orderId = orderId == undefined ? this.getSelectedIds() : [orderId];
      if (orderId.length < 1) {
        Swal.fire({
          title: "",
          text: "请选择要回调的订单!",
          icon: "warning",
          confirmButtonClass: "btn btn-success",
          heightAuto: false,
        });
        return;
      }

      let that = this;
      Swal.fire({
        title: "确定执行回调该订单?",
        icon: "warning",
        buttons: true,
        showConfirmButton: true,
        confirmButtonText: "确定",
        showCancelButton: true,
        cancelButtonText: "取消",
        dangerMode: true,
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          ApiService.post("/api/merchant/withdraw/manage/notify", orderId).then(
            ({ data }) => {
              if (data.success) {
                Swal.fire({
                  title: "",
                  text: "回调成功",
                  icon: "success",
                  confirmButtonClass: "btn btn-success",
                  heightAuto: false,
                  timer: 1500,
                });
                that.getData();
              } else {
                Swal.fire({
                  title: "回调失败！",
                  text: data.message,
                  icon: "warning",
                  confirmButtonClass: "btn btn-success",
                  heightAuto: false,
                  timer: 3000,
                });
              }
            }
          );
        }
      });
    },

    exportWithdraw() {
      let startTime = this.filter.dateRange[0],
        endTime = this.filter.dateRange[1];
      // if (startTime) {
      //   startTime = startTime + " 00:00:00";
      // }
      // if (endTime) {
      //   endTime = endTime + " 23:59:59";
      // }
      let params = {
        startTime: startTime,
        endTime: endTime,
        type: this.filter.type,
        status: this.filter.status,
      };
      params["" + this.filter.field + ""] = this.filter.keyword;

      ApiService.query("/api/merchant/withdraw/export_withdraw", {
        params: params,
        responseType: "blob",
      }).then((res) => {
        if (res.status == 200) {
          // 截取文件名，这里是后端返回了文件名+后缀，如果没有可以自己拼接
          let fileName = decodeURI(
            res.headers["content-disposition"].split(";")[1].split("=")[1]
          );
          // 将`blob`对象转化成一个可访问的`url`
          let url = window.URL.createObjectURL(new Blob([res.data]));
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      });
    },
  },
  computed: {
    pagination: function () {
      return this.table.pagination;
    },
  },
  watch: {
    pagination: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;

    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;

    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>